<template>
  <v-app class="fill-height">
    <v-content>
      <v-container fill-height style="margin-top: 20px">
        <div class="profile">
          <v-card-title>
            <h1>{{ name }} : {{ headerText }}</h1>
          </v-card-title>
          <v-divider />
          <div class="terms-text-box">
            {{ terms }}
          </div>
          <v-snackbar
            v-model="display"
            :color="snackType ? 'success' : 'error'"
            :multi-line="'multi-line'"
            :timeout="snackType ? 1000 : 0"
            :vertical="'vertical'"
            class="toast"
            :type="snackType ? 'success' : 'error'"
          >
            {{ msg }}
            <v-btn dark flat @click="display = false">Close</v-btn>
          </v-snackbar>
        </div>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import { authComputed } from '@/state/helpers'
import companies from '@/services/companies'

export default {
  props: {
    mode: {
      type: String,
      default: 'company-terms',
    },
  },
  data() {
    return {
      name: '',
      display: false,
      snackType: false,
      termId: null,
      terms: '',
      msg: '',
      id: null,
      headerText: null,
      loading: false,
    }
  },
  computed: {
    ...authComputed,
    isModeCompanyTerms() {
      return this.mode === 'company-terms'
    },
    isModeReferralTerms() {
      return this.mode === 'referral-terms'
    },
  },
  watch: {
    $route: {
      deep: true,
      async handler() {
        await this.sync()
      },
    },
  },
  async mounted() {
    await this.sync()
  },
  methods: {
    async sync() {
      this.headerText = this.isModeCompanyTerms
        ? 'Terms & Conditions'
        : 'Referral Terms & Conditions'
      await this.fetchTerms()
    },
    async fetchTerms() {
      try {
        const companyResponse = await companies.getCompany(
          this.$route.params.companyId
        )
        this.name = companyResponse.data?.company?.name

        if (this.isModeCompanyTerms) {
          const companyTermsResponse = await companies.getDefaultCompanyTerms(
            this.$route.params.companyId
          )
          this.terms = companyTermsResponse.data?.companyTerm?.body
        } else if (this.isModeReferralTerms) {
          const referralTermsResponse = await companies.getReferralTerms(
            this.$route.params.companyId
          )
          this.terms = referralTermsResponse?.data?.body
        }
      } catch {
        this.displaySnackBar('Error in retrieving terms', false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
h2 {
  margin: 10px 0 10px 0;
  text-align: center;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.profile {
  position: relative;
  padding: 24px;
  margin: 0 24px 48px 24px;
  background: $white;
  border-radius: 15px;
  width: 100%;
}

.box {
  padding: 10px 90px 0 90px;
}

.bottom {
  display: flex;
  justify-content: center;
}

.toast {
  position: absolute;
  bottom: 2%;
}

.profile ::v-deep .v-card__title {
  padding: 16px 16px 16px 0;
}

.terms-text-box {
  white-space: pre-wrap;
}
</style>
