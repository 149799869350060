import { render, staticRenderFns } from "./terms-legacy.vue?vue&type=template&id=3f15c2e1&scoped=true"
import script from "./terms-legacy.vue?vue&type=script&lang=js"
export * from "./terms-legacy.vue?vue&type=script&lang=js"
import style0 from "./terms-legacy.vue?vue&type=style&index=0&id=3f15c2e1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f15c2e1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VApp,VBtn,VCardTitle,VContainer,VContent,VDivider,VSnackbar})
